<template>
  <div class="culture">
    <OfficialHead ref="header" :homePageSetting="homePageSetting" />
    <!-- banner start -->
    <div class="banner">
      <el-image :src="require('@/assets/banner/culture-banner.png')" class="banner-img" fit="cover" />
    </div>
    <!-- banner end -->
    <!-- swiper start -->
    <div class="swiper">
      <div class="container" v-if="cultureList.length">
        <div class="row">
          <div class="col-12">
            <div class="common-title">
              <div class="title-text">文化广场</div>
              <a class="title-more" :href="`/bigPictureNews?id=${cultureQuery.articleListId}`" target="_blank">
                <span class="more-text">更多</span>
                <el-image :src="require('@/assets/dev/culture-icon.png')" class="more-icon" fit="cover" />
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="swiper-content">
              <swiper
                v-if="cultureList.length <= 2"
                ref="square-swiper"
                :options="squareOptionsSmall"
              >
                <swiper-slide v-for="item of cultureList" :key="item.id">
                  <img :src="item.picture" class="slider-img" @click="cultureLinkTo(item.id)" />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="d-none d-lg-block swiper-button-prev" slot="button-prev"></div>
                <div class="d-none d-lg-block swiper-button-next" slot="button-next"></div>
              </swiper>
              <swiper
                v-else
                ref="square-swiper"
                :options="squareOptionsLarge"
              >
                <swiper-slide v-for="item of cultureList" :key="item.id">
                  <img :src="item.picture" class="slider-img" @click="cultureLinkTo(item.id)" />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="d-none d-lg-block swiper-button-prev" slot="button-prev"></div>
                <div class="d-none d-lg-block swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- swiper end -->
    <!-- art start -->
    <div class="art">
      <div class="container" v-if="artSwiper.length && artList.length">
        <div class="row">
          <div class="col-12">
            <div class="common-title">
              <div class="title-text">文艺之窗</div>
              <a class="title-more" :href="`/bigPictureNews?id=${artQuery.articleListId}`" target="_blank">
                <span class="more-text">更多</span>
                <el-image :src="require('@/assets/dev/culture-icon.png')" class="more-icon" fit="cover" />
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="art-swiper">
              <swiper
                ref="culture-swiper"
                :options="cultureOptions"
              >
                <swiper-slide v-for="item of artSwiper" :key="item.id">
                  <a class="art-img" :href="getDetailUrl(item)" target="_blank">
                    <el-image :src="item.picture" class="img-src" fit="cover" />
                    <div class="img-title">
                      <span class="title-text">{{ item.title }}</span>
                    </div>
                  </a>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
              </swiper>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="art-list">
              <a class="list-item" v-for="item of artList" :key="item.id" :href="getDetailUrl(item)" target="_blank">
                <span class="item-text">{{ item.title }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- art end -->
    <!-- painting start -->
    <div class="painting">
      <div class="container" v-if="paintingSwiper.length && paintingList.length">
        <div class="row">
          <div class="col-12">
            <div class="common-title">
              <div class="title-text">书画摄影</div>
              <a class="title-more" :href="`/bigPictureNews?id=${paintingQuery.articleListId}`" target="_blank">
                <span class="more-text">更多</span>
                <el-image :src="require('@/assets/dev/culture-icon.png')" class="more-icon" fit="cover" />
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="painting-swiper">
              <swiper
                ref="painting-swiper"
                :options="paintingOptions"
              >
                <swiper-slide v-for="item of paintingSwiper" :key="item.id">
                  <div class="painting-img">
                    <el-image :src="item.picture" class="img-src" fit="cover" />
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="painting-list">
              <div class="list-item" v-for="item of paintingList" :key="item.id">
                <el-image :src="item.picture" class="item-img" fit="cover" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- painting end -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import { getHomePageSetting, setSeo } from '../api/defaultDataModel'
import OfficialHead from './OfficialHead'
import OfficialFoot from './OfficialFoot'

export default {
  name: 'Culture',
   components: {
    OfficialHead,
    OfficialFoot
  },
  data () {
    return {
      homePageSetting: getHomePageSetting(),
      squareOptionsSmall: {
        loop: true,
        autoHeight: true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      squareOptionsLarge: {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        autoplay: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination'
        },
        on: {
          progress (progress) {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i)
              const slideProgress = this.slides[i].progress
              let modify = 1
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
              }
              const translate = slideProgress * modify * 260 + 'px'
              const scale = 1 - Math.abs(slideProgress) / 5
              const zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
              slide.transform('translateX(' + translate + ') scale(' + scale + ')')
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1)
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0)
              }
            }
          },
          setTransition (transition) {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i)
              slide.transition(transition)
            }
          }
        }
      },
      cultureOptions: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      paintingOptions: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      cultureQuery: {
        page: 1,
        pageSize: 5,
        articleListId: null
      },
      artQuery: {
        page: 1,
        pageSize: 8,
        articleListId: null
      },
      paintingQuery: {
        page: 1,
        pageSize: 8,
        articleListId: null
      },
      cultureList: [],
      artSwiper: [],
      artList: [],
      paintingSwiper: [],
      paintingList: []
    }
  },
  created () {
    const { cid, lid, tid } = this.$route.query
    const status = cid && lid && tid && /^\d{15,}$/.test(cid) && /^\d{15,}$/.test(lid) && /^\d{15,}$/.test(tid)
    if (status) {
      this.cultureQuery.articleListId = cid
      this.artQuery.articleListId = lid
      this.paintingQuery.articleListId = tid
      this.getSetting()
      this.queryCuLture()
      this.queryArt()
      this.queryPainting()
    } else {
      this.$router.push({ path: '/' })
    }
  },
  mounted() {
    window.removeEventListener('scroll', this.$refs.header.handleScroll)
    this.$refs.header.navigationHeaderClass = ['header', 'header-fixed']
  },
  methods: {
    cultureLinkTo(id) {
      const { href } = this.$router.resolve({ path: `/importantDetail?id=${id}` })
      window.open(href, '_blank')
    },
    async getSetting () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/homePageSetting/getSetting')
      const { data, code } = res.data
      if (code === 8000) {
        this.homePageSetting = data
        setSeo(
          this.homePageSetting.seoTitle,
          this.homePageSetting.seoDescription,
          this.homePageSetting.seoKeyword
        )
      }
    },
    async queryCuLture () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.cultureQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.cultureList = list
            if (this.cultureList.length <= 1) {
              this.squareOptionsSmall.loop = false
            }
          }
        }
      }
    },
    async queryArt () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.artQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list) && list.length > 5) {
            this.artSwiper = list.slice(0, 4)
            this.artList = list.slice(4, list.length)
          } else {
            this.artSwiper = list
          }
        }
      }
    },
    async queryPainting () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.paintingQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list) && list.length > 5) {
            this.paintingSwiper = list.slice(0, 4)
            this.paintingList = list.slice(4, list.length)
          } else {
            this.paintingSwiper = list
          }
        }
      }
    },
    handlePages (list) {
      const pages = []
      list.forEach((item, index) => {
        const page = Math.floor(index / 5)
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    },
    getDetailUrl(news) {
      if (news) {
        if (1 === news.articleType) {
          return `/importantDetail?id=${news.id}`
        }
        return `/detail?id=${news.id}`
      }
      return '/'
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../assets/css/font.css';
  .culture {
    width: 100%;
    min-height: 100vh;
    padding-top: 88px;
    font-family: pingFangSC-Medium !important;
    color: #222222;
    .common-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;
      .title-text {
        margin-bottom: 10px;
        font-size: 48px;
        font-weight: 700;
      }
      .title-more {
        display: flex;
        align-items: center;
        .more-text {
          margin-right: 5px;
          font-weight: 700;
        }
        .more-icon {
          display: flex;
          align-items: center;
        }
      }
    }
    .banner {
      width: 100%;
      height: 360px;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    .swiper {
      margin-top: 100px;
      .swiper-content {
        width: 100%;
        .swiper-container {
          padding-bottom: 60px;
          .swiper-slide {
            position: relative;
            width: 520px;
            box-sizing: border-box;
            padding: 0 80px;
            .slider-img {
              object-fit: cover;
              width: 100%;
              height: 400px;
              box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
              cursor: pointer;
            }
            .slider-content {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              position: absolute;
              bottom: 0;
              width: calc(100% - 160px);
              .content-text {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 12px 20px;
                font-size: 18px;
                color: #ffffff;
                background: rgba(198, 43, 37, 0.902);
              }
            }
          }
          .swiper-pagination {
            width: 100%;
            bottom: 10px;
            /deep/ .swiper-pagination-bullet {
              opacity: 1;
              width: 15px;
              height: 15px;
              border: 3px solid #fff;
              margin: 0 5px;
              background-color: #cccccc;
            }
            /deep/ .swiper-pagination-bullet-active {
              border: 3px solid #0b46b1;
              background-color: #fff;
            }
          }
          .swiper-button-prev {
            top: 45%;
            left: 0;
            width: 45px;
            height: 45px;
            background: url('../assets/dev/policy-prev.png') no-repeat;
            background-position: 0 0;
            background-size: 100%;
          }
          .swiper-button-next {
            top: 45%;
            right: 0;
            width: 45px;
            height: 45px;
            background: url('../assets/dev/policy-next.png') no-repeat;
            background-size: 100%;
          }
        }
      }
    }
    .art {
      margin-top: 100px;
      .art-swiper {
        .swiper-container {
          .art-img {
            position: relative;
            display: block;
            width: 100%;
            height: 398px;
            .img-src {
              width: 100%;
              height: 100%;
            }
            .img-title {
              display: flex;
              align-items: center;
              width: 100%;
              height: 70px;
              position: absolute;
              bottom: 0;
              box-sizing: border-box;
              padding: 0 15px;
              font-size: 18px;
              color: #ffffff;
              z-index: 99;
              background: rgba(0, 0, 0, 0.6);
              .title-text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                width: 80%;
              }
            }
          }
          .swiper-pagination {
            left: initial;
            right: 15px;
            bottom: 0;
            display: flex;
            align-items: center;
            width: initial;
            height: 70px;
            font-size: 14px;
            color: #ffffff;
            /deep/ .swiper-pagination-current {
              margin-right: 8px;
              font-size: 32px;
              font-weight: 700; 
            }
            /deep/ .swiper-pagination-total {
              margin-left: 2px;
            }
          }
          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }
      }
      .art-list {
        .list-item {
          display: flex;
          align-items: center;
          height: 92px;
          line-height: 32px;
          box-sizing: border-box;
          padding: 0 20px;
          margin-bottom: 10px;
          background: #f5f5f5;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
          .item-text {
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            /*控制在3行*/
            -webkit-line-clamp:2;
            -webkit-box-orient:vertical;
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
    }
    .painting {
      margin-top: 100px;
      .painting-swiper {
        .painting-img {
          width: 100%;
          height: 398px;
          .img-src {
            width: 100%;
            height: 100%;
          }
        }
        .swiper-pagination {
          right: 15px;
          left: initial;
          width: initial;
          /deep/ .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
          }
          /deep/ .swiper-pagination-bullet-active {
            background: #ffffff;
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }
      .painting-list {
        overflow: hidden;
        .list-item {
          float: left;
          width: 50%;
          height: 194px;
          box-sizing: border-box;
          margin-top: 10px;
          &:nth-of-type(1), &:nth-of-type(2) {
            margin-top: 0;
          }
          &:nth-of-type(2n+1) {
            padding-right: 5px;
          }
          &:nth-of-type(2n+2) {
            padding-left: 5px;
          }
          .item-img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .footer {
      margin-top: 100px;
    }
  }
  @media (max-width: 992px) {
    .culture {
      .painting {
        .painting-list {
          margin-top: 10px;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .culture {
      padding-top: 64px;
      .common-title {
        margin-bottom: 40px;
        .title-text {
          font-size: 36px;
        }
      }
      .swiper {
        margin-top: 60px;
        .swiper-content {
          .swiper-container {
            .swiper-slide {
              width: 100%;
              padding: 0 0;
              .slider-img {
                height: 360px;
              }
            }
          }
        }
      }
      .art {
        margin-top: 60px;
        .art-swiper {
          .swiper-container {
            .art-img {
              height: 320px;
              .img-title {
                height: 66px;
                .title-text {
                  font-size: 16px;
                }
              }
            }
            .swiper-pagination {
              /deep/ .swiper-pagination-current {
                margin-right: 8px;
                font-size: 28px;
                font-weight: 700; 
              }
              /deep/ .swiper-pagination-total {
                margin-left: 2px;
              }
            }
          }
        }
        .art-list {
          .list-item {
            &:nth-of-type(1) {
              margin-top: 10px;
            }
            .item-text {
              font-size: 16px;
            }
          }
        }
      }
      .painting {
        margin-top: 60px;
        .painting-swiper {
          .painting-img {
            height: 320px;
          }
        }
        .painting-list {
          .list-item {
            height: 140px;
          }
        }
      }
      .footer {
        margin-top: 60px;
      }
    }
  }
</style>
